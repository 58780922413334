var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container--fluid"},[_c('app-widget',{attrs:{"title":"Basic Usage"}},[_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"max-height":"600","tile":""}},_vm._l((_vm.classes),function(ref){
            var c = ref[0];
            var text = ref[1];
            var size = ref[2];
            var weight = ref[3];
            var spacing = ref[4];
            var margin = ref[5];
return _c('v-item',{key:c,scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"px-4 pt-4 pb-3",staticStyle:{"cursor":"pointer"},attrs:{"color":active ? 'grey lighten-4' : undefined,"tile":""},on:{"click":toggle}},[_c('div',{staticClass:"transition-swing",class:[("text-" + c), active && ("mb-" + margin)],domProps:{"textContent":_vm._s(text)}}),_c('v-expand-transition',[(active)?_c('v-responsive',{attrs:{"max-width":"350"}},[_c('div',{staticClass:"d-flex justify-space-between text-caption"},[_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Font ")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Roboto ")])]),_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Weight ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(weight)}})]),_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Size ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(size)}})]),_c('div',[_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_vm._v(" Letter spacing ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(spacing)}})])])]):_vm._e()],1)],1)]}}],null,true)})}),1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }